import { BooleanField, Datagrid, DateField, FunctionField, List, ReferenceField, TextField } from 'react-admin';

export const ExaminationList = () => (
  <List>
    <Datagrid rowClick="show">
      <ReferenceField source="patientId" reference="patients">
        <FunctionField label="Name" render={(record: any) => `${record.name} ${record.surname}`} />
      </ReferenceField>
      <DateField source="date" />
      <TextField source="place" />
      <BooleanField source="athlete" />
      <BooleanField source="newTherapeuticPath" />
      <TextField source="visitReason" />
      <TextField source="target" />
      <TextField source="manualMeasurement" />
      <TextField source="instrumentMeasurement" />
      <TextField source="instrumentUsed" />
    </Datagrid>
  </List>
);
