import { Datagrid, DateField, EmailField, List, TextField } from 'react-admin';

export const PatientList = () => (
  <List>
    <Datagrid rowClick="show">
      <TextField source="userId" />
      <TextField source="name" />
      <TextField source="surname" />
      <TextField source="sex" />
      <DateField source="dateOfBirth" />
      <TextField source="placeOfBirth" />
      <TextField source="ethnicity" />
      <TextField source="phone" />
      <EmailField source="email" />
    </Datagrid>
  </List>
);
