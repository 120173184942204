import { FirebaseDataProvider } from 'react-admin-firebase';
import { combineDataProviders } from 'react-admin';
import { firebaseConfig } from '../../firebaseConfig';
import { functionsDataProvider } from './functionsDataProvider';

function removeUndefinedKeys(data: any) {
  return JSON.parse(JSON.stringify(data));
}

const firestoreDataProvider = FirebaseDataProvider(firebaseConfig, {
  transformToDb: (_, documentData) => removeUndefinedKeys(documentData),
});

export const dataProvider = combineDataProviders((resource) => {
  switch (resource) {
    case 'patients':
    case 'examinations':
    case 'userPreferences':
    case 'diets':
    case 'sponsors':
      return firestoreDataProvider;
    case 'users':
      return functionsDataProvider;
    default:
      throw new Error(`Unknown resource: ${resource}`);
  }
});
