import { JsonField } from 'react-admin-json-view';
import { NumberField, Show, SimpleShowLayout, TextField } from 'react-admin';

export const DietShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="name" />
      <NumberField source="kcal" />
      <NumberField source="phase" />
      <JsonField
        source="data"
        jsonString
        reactJsonOptions={{
          name: null,
          collapsed: true,
          enableClipboard: false,
          displayDataTypes: false,
        }}
      />
    </SimpleShowLayout>
  </Show>
);
