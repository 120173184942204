import {
  ArrayInput,
  Edit,
  EditProps,
  ImageField,
  ImageInput,
  Labeled,
  NumberInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';

export const SponsorEdit = (props: EditProps) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source={'name'} label={'Nome'} />

      <ImageInput source={'logo'} label={'Logo'}>
        <ImageField source="src" title="title" />
      </ImageInput>

      <ImageInput source={'banner'} label={'Banner'}>
        <ImageField source="src" title="title" />
      </ImageInput>

      <Labeled label={'Integratore principale'} fullWidth>
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <TextInput source={'supplement.name'} label={'Nome'} />
            <TextInput source={'supplement.description'} label={'Descrizione'} />
            <NumberInput source={'supplement.proteinsGrams'} label={'Grammi di proteine'} />
            <NumberInput source={'supplement.bagsNumber'} label={'Numero di bustine'} />
          </div>
        </>
      </Labeled>

      <ArrayInput source={'supplements'} label={'Integratori dello sponsor'}>
        <SimpleFormIterator inline>
          <TextInput source={'description'} label={'Descrizione'} />
        </SimpleFormIterator>
      </ArrayInput>

      <ArrayInput source={'lowCarbFood'} label={'Alimenti low carb'}>
        <SimpleFormIterator inline>
          <TextInput source={'description'} label={'Descrizione'} />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);
