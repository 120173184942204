import { Edit, ReferenceInput, SelectInput, SimpleForm, TextInput, required } from 'react-admin';

export const UserPreferencesEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput disabled source="userId" />

      <SelectInput
        source="formulaProfile"
        validate={required()}
        choices={[
          { id: 'classic', name: 'Classico' },
          { id: 'battistini-morini', name: 'Kalus automation' },
        ]}
      />

      <TextInput source="userInfo.name" />
      <TextInput source="userInfo.phone" />
      <TextInput source="userInfo.address" />
      <TextInput source="userInfo.email" />

      <ReferenceInput source="sponsor" reference="sponsors">
        <SelectInput optionText={(sponsor) => sponsor.name} />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
