import { ArrayField, Datagrid, ImageField, List, NumberField, TextField, WrapperField } from 'react-admin';

export const SponsorList = () => (
  <List>
    <Datagrid rowClick={'show'}>
      <TextField source={'name'} label={'Nome'} />
      <ImageField source={'logo.src'} label={'Logo'} />
      <ImageField source={'banner.src'} label={'Banner'} />

      <WrapperField label={'Integratore principale'}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TextField source={'supplement.name'} />
          <TextField source={'supplement.description'} />
          <NumberField source={'supplement.proteinsGrams'} />
          <NumberField source={'supplement.bagsNumber'} />
        </div>
      </WrapperField>

      <ArrayField source={'supplements'} label={'Integratori dello sponsor'}>
        <Datagrid bulkActionButtons={false}>
          <TextField source={'description'} label={false} />
        </Datagrid>
      </ArrayField>

      <ArrayField source={'lowCarbFood'} label={'Alimenti low carb'}>
        <Datagrid bulkActionButtons={false}>
          <TextField source={'description'} label={false} />
        </Datagrid>
      </ArrayField>
    </Datagrid>
  </List>
);
