import { BooleanField, DateField, FunctionField, ReferenceField, Show, SimpleShowLayout, TextField } from 'react-admin';

export const ExaminationShow = () => (
  <Show>
    <SimpleShowLayout>
      <ReferenceField source="patientId" reference="patients">
        <FunctionField label="Name" render={(record: any) => `${record.name} ${record.surname}`} />
      </ReferenceField>
      <DateField source="date" showTime />
      <TextField source="place" />
      <BooleanField source="athlete" />
      <BooleanField source="newTherapeuticPath" />
      <TextField source="visitReason" />
      <TextField source="target" />
      <TextField source="manualMeasurement" />
      <TextField source="instrumentMeasurement" />
      <TextField source="instrumentUsed" />
    </SimpleShowLayout>
  </Show>
);
