import { DataProvider, fetchUtils } from 'react-admin';
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';
import { firebaseConfig } from '../../firebaseConfig';
import { initializeApp } from 'firebase/app';
import { stringify } from 'query-string';

const apiUrl = '';
const httpClient = fetchUtils.fetchJson;

const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);

if (process.env.NODE_ENV === 'development') {
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

export const functionsDataProvider: DataProvider = {
  getList: (resource, params) => {
    // const { page, perPage } = params.pagination;
    // const { field, order } = params.sort;
    // const query = {
    //   sort: JSON.stringify([field, order]),
    //   range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
    //   filter: JSON.stringify(params.filter),
    // };
    const getUsers = httpsCallable<any>(functions, 'getUsers');

    return getUsers().then((result: any) => {
      return result.data;
    });
  },

  getOne: (resource, params) =>
    httpsCallable<any>(
      functions,
      'getUser'
    )({ uid: params.id }).then((result: any) => {
      return { data: { id: result.data.uid, ...result.data } as any };
    }),

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };

    const getUsers = httpsCallable<any>(functions, 'getUsers');

    return getUsers(query).then((result: any) => {
      return result.data;
    });
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get('content-range')?.split('/').pop() ?? '', 10),
    }));
  },

  create: (resource, params) => {
    const createUser = httpsCallable<any>(functions, 'createUser');

    return createUser(params.data).then((result: any) => {
      return { data: { id: result.data.uid, ...result.data } as any };
    });
  },

  update: (resource, params) => {
    const updateUser = httpsCallable<any>(functions, 'updateUser');

    return updateUser({ uid: params.id, ...params }).then((result: any) => {
      return { data: { id: result.data.uid, ...result.data } as any };
    });
  },

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json })),

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'DELETE',
      body: JSON.stringify(params.ids),
    }).then(({ json }) => ({ data: json }));
  },
};
