import { FunctionField, ReferenceField, SelectField, Show, SimpleShowLayout, TextField } from 'react-admin';

export const UserPreferencesShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="userId" />

      <SelectField
        source="formulaProfile"
        choices={[
          { id: 'classic', name: 'Classico' },
          { id: 'battistini-morini', name: 'Kalus automation' },
        ]}
      />

      <TextField source="userInfo.name" />
      <TextField source="userInfo.phone" />
      <TextField source="userInfo.address" />
      <TextField source="userInfo.email" />

      <ReferenceField reference={'sponsors'} source={'sponsor'}>
        <FunctionField render={(sponsor: any) => sponsor.name} />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);
