import { Create, CreateProps, NumberInput, SimpleForm, TextInput } from 'react-admin';

export const DietCreate = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="Nome" name={'name'} />
      <NumberInput source="Kcal" name={'kcal'} />
      <NumberInput source="Fase" name={'phase'} />
      <TextInput source="Dati Dieta" multiline rows={10} name={'data'} />
    </SimpleForm>
  </Create>
);
