import { DateField, EmailField, Show, SimpleShowLayout, TextField } from 'react-admin';

export const PatientShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="userId" />
      <TextField source="name" />
      <TextField source="surname" />
      <TextField source="sex" />
      <DateField source="dateOfBirth" />
      <TextField source="placeOfBirth" />
      <TextField source="ethnicity" />
      <TextField source="phone" />
      <EmailField source="email" />
    </SimpleShowLayout>
  </Show>
);
