import {
  ArrayField,
  Datagrid,
  ImageField,
  Labeled,
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
  WrapperField,
} from 'react-admin';

export const SponsorShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source={'name'} label={'Nome'} />
      <ImageField source={'logo.src'} label={'Logo'} />
      <ImageField source={'banner.src'} label={'Banner'} />

      <WrapperField label={'Integratore principale'}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Labeled label={'Nome'}>
            <TextField source={'supplement.name'} />
          </Labeled>
          <Labeled label={'Descrizione'}>
            <TextField source={'supplement.description'} />
          </Labeled>
          <Labeled label={'Grammi di proteine'}>
            <NumberField source={'supplement.proteinsGrams'} />
          </Labeled>
          <Labeled label={'Numero di bustine'}>
            <NumberField source={'supplement.bagsNumber'} />
          </Labeled>
        </div>
      </WrapperField>

      <ArrayField source={'supplements'} label={'Integratori dello sponsor'}>
        <Datagrid bulkActionButtons={false}>
          <TextField source={'description'} label={false} />
        </Datagrid>
      </ArrayField>

      <ArrayField source={'lowCarbFood'} label={'Alimenti low carb'}>
        <Datagrid bulkActionButtons={false}>
          <TextField source={'description'} label={false} />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);
