import { Admin, Resource } from 'react-admin';
import { DietCreate, DietEdit, DietList, DietShow } from './diets';
import { ExaminationList } from './examinations/ExaminationList';
import { ExaminationShow } from './examinations/ExaminationShow';
import { PatientList } from './patients/PatientList';
import { PatientShow } from './patients/PatientShow';
import { SponsorCreate, SponsorEdit, SponsorList, SponsorShow } from './sponsors';
import { UserPreferencesEdit } from './userPreferences/UserPreferencesEdit';
import { UserPreferencesList } from './userPreferences/UserPreferencesList';
import { UserPreferencesShow } from './userPreferences/UserPreferencesShow';
import { authProvider } from './authProvider';
import { dataProvider } from './dataProvider';
import ArticleIcon from '@mui/icons-material/Article';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PeopleIcon from '@mui/icons-material/People';
import VisibilityIcon from '@mui/icons-material/Visibility';

const App = () => (
  <Admin authProvider={authProvider} dataProvider={dataProvider}>
    <Resource name="patients" options={{ label: 'Pazienti' }} icon={PeopleIcon} list={PatientList} show={PatientShow} />
    <Resource
      name="examinations"
      options={{ label: 'Esami' }}
      icon={VisibilityIcon}
      list={ExaminationList}
      show={ExaminationShow}
    />
    <Resource
      name="userPreferences"
      options={{ label: 'Preferenze utenti' }}
      icon={ManageAccountsIcon}
      list={UserPreferencesList}
      show={UserPreferencesShow}
      edit={UserPreferencesEdit}
    />
    <Resource
      name="diets"
      options={{ label: 'Diete' }}
      icon={ArticleIcon}
      list={DietList}
      create={DietCreate}
      edit={DietEdit}
      show={DietShow}
    />
    <Resource
      name="sponsors"
      options={{ label: 'Sponsor' }}
      icon={MonetizationOnIcon}
      list={SponsorList}
      create={SponsorCreate}
      edit={SponsorEdit}
      show={SponsorShow}
    />
  </Admin>
);

export default App;
