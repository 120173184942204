import { Edit, EditProps, NumberInput, SimpleForm, TextInput } from 'react-admin';

export const DietEdit = (props: EditProps) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="Nome" name={'name'} />
      <NumberInput source="Kcal" name={'kcal'} />
      <NumberInput source="Fase" name={'phase'} />
      <TextInput source="Dati Dieta" multiline rows={10} name={'data'} />
    </SimpleForm>
  </Edit>
);
